<template>
	<Layout>
		<PageHeader :title="title" :items="items" />

		<div class="row">
			<div
				v-for="widget in widgetData"
				:key="widget.text"
				class="col-xl-4 col-md-8"
			>
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">{{ widget.number }}</h3>
						<p class="text-muted mb-0">{{ widget.text }}</p>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>

		<div class="row">
			<div class="col-6 col-md-4">
				<div class="form-group">
					<label for="branch">Select Year</label>
					<date-picker
						value-type="YYYY-MM-DD"
						format="YYYY"
						v-model="filters.monthly"
						type="year"
						lang="en"
						placeholder="Select A Year"
					/>
				</div>
			</div>
			<div class="col-6 col-md-4">
				<div class="form-group">
					<label for="branch">Select Month</label>
					<date-picker
						value-type="YYYY-MM-DD"
						format="MMMM YYYY"
						v-model="filters.weekly"
						type="month"
						lang="en"
						placeholder="Select Month"
					/>
				</div>
			</div>
		</div>

		<div class="row my">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<h4 class="header-title">Revenue vs Expense vs Net Profit</h4>
						<p class="sub-header">
							The relationship between revenue, expense & net profit over
							filtered time period
						</p>
						<LineChart ref="lineChart" />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 col-sm-12">
				<div class="form-group">
					<label for="overallBranch">Please Select A Branch</label>
					<select
						class="form-control"
						name="overallBranch"
						id="overallBranch"
						v-model="selectedBranch"
					>
						<template v-for="(branch, index) in branches">
							<option :key="`BrancItem-${index}`" :value="branch._id">
								{{ branch.name }}
							</option>
						</template>
					</select>
				</div>
			</div>
			<div class="col-lg-12">
				<b-card>
					<h4 class="header-title">Machine Overall Performance</h4>
					<p class="sub-header">
						Full report of machine performance over the course of them being
						active in the business
					</p>
					<b-input placeholder="Search here..." v-model="search" />
					<br />
					<b-table
						thead-class="red-t-head"
						:filter="search"
						outlined
						:per-page="perPage"
						:current-page="currentPage"
						hover
						:fields="tableFields"
						:items="tableItems"
					></b-table>
					<b-pagination
						:total-rows="tableItems && tableItems.length"
						:per-page="perPage"
						v-model="currentPage"
					></b-pagination>
				</b-card>
			</div>
		</div>
	</Layout>
</template>

<script>
	import appConfig from "../../../../../app.config";
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import DatePicker from "vue2-datepicker";
	import { mapActions, mapGetters, mapState } from "vuex";
	import LineChart from "@/components/charts/customLineChart";
	import dayjs from "dayjs";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	/**
	 * Starter component
	 */
	export default {
		page: {
			title: "Super Admin Dashboard",
			meta: [{ name: "description", content: appConfig.description }],
		},
		data() {
			return {
				// Intervals for polling data
				widgetInterval: null,
				graphInterval: null,
				tableInterval: null,
				title: "Dashboard",
				items: [{ text: "Dashboard", href: "/" }],
				selectedBranch: "",
				widgetData: {
					gameboxes: {
						number: "0",
						text: "Total Machines In Service",
						chartColor: "#1abc9c",
					},
					profits: {
						number: "$0",
						text: "This Week's Profit",
						chartColor: "#f672a7",
					},
					commissions: {
						number: "$0",
						text: "This Week's Commission Payout",
						chartColor: "#6c757d",
					},
				},
				//Machine Performance table headers
				currentPage: 1,
				perPage: 5,
				search: "",
				tableFields: [
					{
						label: "Serial Number",
						key: "serialNumber",
					},
					{
						label: "Machine Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Total Profit",
						key: "profits",
						type: "money",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
					{
						label: "Total Expenditure",
						key: "expenditure",
						type: "money",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
				],
				// Array used to store machine performance data
				tableItems: [],

				//Line chart vars
				labelList: [],
				expenseList: [],
				profitList: [],
				revenueList: [],

				// Date filter vars
				filters: {
					monthly: "",
					weekly: "",
				},
			};
		},
		//Registered components
		components: {
			Layout,
			PageHeader,
			LineChart,
			DatePicker,
		},
		// Get data before the component is rendered
		async beforeMount() {
			if (!this.totalGameboxes) await this.getGameboxes();
			// Get branches and set the selected one to the first on in the array
			await this.getBranches();
			this.selectedBranch = this.branches[0]?._id;
			// Set this month to the selected month for the line chart
			this.filters.monthly = dayjs().format("YYYY-MM");
			// Call functions that will get data
			await this.loadWidgetData();
			await this.loadTableData();
			await this.loadGraphData();
		},
		mounted() {
			this.widgetInterval = setInterval(() => {
				this.loadWidgetData();
			}, 3000);
			this.tableInterval = setInterval(() => {
				this.loadTableData();
			}, 2000);
		},
		destroyed() {
			clearInterval(this.widgetInterval);
			clearInterval(this.tableInterval);
		},
		computed: {
			//Vuex states & getters
			...mapState("gameboxModule", ["totalGameboxes", "gameboxes"]),
			...mapState("branchModule", ["branches"]),
			...mapGetters("gameboxModule", ["getGameboxById"]),
		},

		methods: {
			//Vuex actions
			...mapActions("transactionsModule", [
				"getTransactions",
				"getMonthOrWeekTransaction",
				"getTransactionSummary",
			]),
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("gameboxModule", ["getGameboxes"]),
			/**
			 * Method used to load line chart data
			 */
			async loadGraphData() {
				// Objects used to store data lines on the graph
				let expense = {};
				let profit = {};
				let revenue = {};

				// get dates based on selected filter
				const { startDate, endDate, period } = this.getDates();

				// Get transactions from API based on selected dates
				const result = await this.getMonthOrWeekTransaction({
					period,
					startDate,
					endDate,
				});

				// Check if an array was returned from API
				if (Array.isArray(result.data)) {
					//Extract data from results
					const { data } = result;
					this.profitList = [];
					this.expenseList = [];
					this.revenueList = [];

					if (period == "monthly") {
						for (let i = 0; i < data.length; i++) {
							let summary = data[i];
							let currMonth = parseInt(dayjs(summary._id).format("M"));
							this.profitList[currMonth - 1] = data[i].profits;
							this.expenseList[currMonth - 1] = data[i].totalOut;
							this.revenueList[currMonth - 1] = data[i].totalIn;
						}
					} else if (period == "weekly") {
						for (let i = 0; i < data.length; i++) {
							let summary = data[i];
							let week = summary._id;
							this.profitList[week - 1] = summary.profits;
							this.expenseList[week - 1] = summary.totalOut;
							this.revenueList[week - 1] = summary.totalIn;
						}
						this.profitList.splice(0, this.profitList.length - 4);
						this.expenseList.splice(0, this.expenseList.length - 4);
						this.revenueList.splice(0, this.revenueList.length - 4);
					}

					for (let i = 0; i < this.profitList.length; i++) {
						if (this.profitList?.[i] == undefined) {
							this.profitList[i] = 0;
						}
					}

					for (let i = 0; i < this.expenseList.length; i++) {
						if (this.expenseList?.[i] == undefined) {
							this.expenseList[i] = 0;
						}
					}
					for (let i = 0; i < this.revenueList.length; i++) {
						if (this.revenueList?.[i] == undefined) {
							this.revenueList[i] = 0;
						}
					}
				}
				profit = {
					label: "Profits",
					color: "#118C4F",
					data: this.profitList,
				};

				expense = {
					label: "Expense",
					color: "#FF2400",
					data: this.expenseList,
				};
				revenue = {
					label: "Revenue",
					color: "#f5bf42",
					data: this.revenueList,
				};

				if (this.filters.monthly)
					this.labelList = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
				else this.labelList = ["Week #1", "Week #2", "Week #3", "Week #4"];

				this.$refs["lineChart"].render(this.labelList, [
					expense,
					profit,
					revenue,
				]);
			},

			/**
			 * Method used ot get data for the cards at the top of the page
			 */
			async loadWidgetData() {
				//Set the total number of gamboxes
				//Value comes from vuex store
				this.widgetData.gameboxes.number = this.totalGameboxes;

				// Set the start date & end date of the current week
				const weekStart = dayjs().startOf("week").format("YYYY-MM-DD");
				const weekEnd = dayjs().endOf("week").format("YYYY-MM-DD");

				// Sen request to get the weekly transaction summary
				//Start & enddate are important here
				const weeksTransactions = await this.getMonthOrWeekTransaction({
					period: "weekly",
					startDate: weekStart,
					endDate: weekEnd,
				});
				// Chekc if a succes response was sent
				if (weeksTransactions.status == 200) {
					//Check if data is an array with more than one element
					if (
						Array.isArray(weeksTransactions.data) &&
						weeksTransactions.data.length > 0
					) {
						const dated = new Date();
						const weekOfMonth = (0 | (dated.getDate() / 7)) + 1;
						this.widgetData.commissions.number = formatter.format(
							weeksTransactions.data[0].commission
						);
						this.widgetData.profits.number = formatter.format(
							weeksTransactions.data[0].profits
						);
					}
				}
			},

			/**
			 * Method used to get table for the machine performance
			 */
			async loadTableData() {
				// DO nothign if a branch is not selected
				if (!this.selectedBranch) return;
				//Get summary based on the selected branch
				const transactionSummary = await this.getTransactionSummary({
					groupBy: "gamebox",
					branchId: this.selectedBranch,
				});
				//Check if a succes response was sent
				if (transactionSummary.status == 200) {
					//Extract data from response
					const { data } = transactionSummary;
					this.tableItems = [];
					for (let i = 0; i < data.length; i++) {
						const gamebox = this.getGameboxById(data[i].gamebox);
						if (gamebox) {
							this.tableItems.push({
								serialNumber: gamebox ? gamebox.serialNumber : "",
								name: gamebox ? gamebox.name : "",
								profits: data[i].profits,
								expenditure: data[i].totalOut,
							});
						}
					}
					// Sort tableItems by name
					this.tableItems.sort((a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					});
				}
			},

			/**
			 * Get dates based on the populated filter for the line chart
			 */
			getDates() {
				let startDate = "";
				let endDate = "";
				let period = "monthly";

				if (this.filters.weekly != "") {
					period = "weekly";
					startDate = dayjs(this.filters.weekly)
						.startOf("month")
						.format("YYYY-MM-DD");
					endDate = dayjs(this.filters.weekly)
						.endOf("month")
						.format("YYYY-MM-DD");
				} else if (this.filters.monthly != "") {
					period = "monthly";
					startDate = dayjs(this.filters.monthly)
						.startOf("year")
						.format("YYYY-MM-DD");
					endDate = dayjs(this.filters.monthly)
						.endOf("year")
						.format("YYYY-MM-DD");
				}
				return { startDate, endDate, period };
			},
		},

		watch: {
			selectedBranch: function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTableData();
				}
			},
			"filters.weekly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.filters.monthly = "";
						this.loadGraphData();
					}
				}
			},

			"filters.monthly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.filters.weekly = "";
						this.loadGraphData();
					}
				}
			},
		},
	};
</script>
