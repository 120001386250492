<script>
import { Line } from 'vue-chartjs'

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
});

export default {
  extends: Line,

  methods: {
    
    render(labelList, values) {
      const datasets = [];
      for (let i = 0; i < values.length; i++) {
        datasets.push(
          {
              label: values[i].label,
              fill: true,
              backgroundColor: 'transparent',
              borderColor: values[i].color || '#3bafda',
              borderDash: [5, 5],
              data: values[i].data,
          },
        )
      }
      
      this.renderChart(
        {
          labels: labelList,
          datasets: datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            intersect: false,
            callbacks: {
              label: function(t, d) {
                var xLabel = d.datasets[t.datasetIndex].label;
                var yLabel = formatter.format(t.yLabel);  
                return xLabel + ': ' + yLabel;
              }
            }
          },
          hover: {
            intersect: true,
          },
          plugins: {
            filler: {
              propagate: false,
            },
          },
          scales: {
            yAxes: [
              { 
                ticks: {
                    callback: (label, index, labels) => {return formatter.format(label)}
                },
                display: true,
                borderDash: [5, 5],
                gridLines: {
                  display: false
                },
              },
            ],
           
            xAxes: [{
              reverse: true,
              gridLines: {
                color: 'rgba(0,0,0,0.05)',
              },
            },],

          },
        }
      )
    }
  }
}
</script>